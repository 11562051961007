import { default as _91_46_46_46path_939rgG6aQ0l8Meta } from "/home/appveyor/projects/swm-ecom-v2/nuxt-app/pages/[...path].vue?macro=true";
import { default as AccessDenied9g0nZh6B9mMeta } from "/home/appveyor/projects/swm-ecom-v2/nuxt-app/pages/AccessDenied.vue?macro=true";
import { default as AccessRestrictedDPqkGVyN4MMeta } from "/home/appveyor/projects/swm-ecom-v2/nuxt-app/pages/AccessRestricted.vue?macro=true";
import { default as ClientErrorTestjiJbbymSQHMeta } from "/home/appveyor/projects/swm-ecom-v2/nuxt-app/pages/ClientErrorTest.vue?macro=true";
import { default as ServerErrorTestpjpVoiNdyZMeta } from "/home/appveyor/projects/swm-ecom-v2/nuxt-app/pages/ServerErrorTest.vue?macro=true";
export default [
  {
    name: "path",
    path: "/:path(.*)*",
    component: () => import("/home/appveyor/projects/swm-ecom-v2/nuxt-app/pages/[...path].vue").then(m => m.default || m)
  },
  {
    name: "AccessDenied",
    path: "/AccessDenied",
    component: () => import("/home/appveyor/projects/swm-ecom-v2/nuxt-app/pages/AccessDenied.vue").then(m => m.default || m)
  },
  {
    name: "AccessRestricted",
    path: "/AccessRestricted",
    meta: AccessRestrictedDPqkGVyN4MMeta || {},
    component: () => import("/home/appveyor/projects/swm-ecom-v2/nuxt-app/pages/AccessRestricted.vue").then(m => m.default || m)
  },
  {
    name: "ClientErrorTest",
    path: "/ClientErrorTest",
    component: () => import("/home/appveyor/projects/swm-ecom-v2/nuxt-app/pages/ClientErrorTest.vue").then(m => m.default || m)
  },
  {
    name: "ServerErrorTest",
    path: "/ServerErrorTest",
    meta: ServerErrorTestpjpVoiNdyZMeta || {},
    component: () => import("/home/appveyor/projects/swm-ecom-v2/nuxt-app/pages/ServerErrorTest.vue").then(m => m.default || m)
  }
]